import React from "react";

const Section = ({ children, title, className }) => (
  <section className={className}>
    <h2>
      <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
        {title}
      </span>
    </h2>
    <div className="mt-10">{children}</div>
  </section>
);

Section.defaultProps = {
  className: "max-w-prose mx-auto my-6",
};

export default Section;
