import * as React from "react";
import Section from "../components/commons/section";
import OurWork from "../components/home/our-work";
import VideoHeader from "../components/home/video-header";
import Layout from "../components/layout/layout";
import usz from "../resources/assets/usz.png";
import uzh from "../resources/assets/uzh.png";
import knz from "../resources/assets/knz.png";
import { StaticImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import Update from "../components/commons/update";
import { parseStory } from "../components/utils";

// markup
const Index = ({ data }) => {
  const upds = data.upds.nodes
    .map(parseStory)
    .sort((a, b) => b.content.date - a.content.date);
  return (
    <Layout>
      <VideoHeader />
      <Section className="max-w-7xl mx-auto mb-6 px-4" title="Latest Updates">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {(upds ?? []).map((upd) => (
            <Update upd={upd} key={upd.slug} />
          ))}
        </div>
      </Section>
      <OurWork />
      {/* <Section className="max-w-7xl mx-auto my-12 px-4" title="Team">
        <StaticImage
          src="../resources/assets/zh1.jpg"
          alt=""
          layout="fullWidth"
          formats={["webp", "auto"]}
          placeholder="tracedSVG"
          className="rounded-2xl shadow-lg"
          aspectRatio={2}
        />
        <blockquote className="italic text-center text-slate-600 text-2xl mt-2">
          AstranLab team
        </blockquote>
      </Section> */}
      <Section
        className="max-w-7xl mx-auto my-12 px-4"
        title="Support and Funding"
      >
        <div className="gap-8 md:gap-14 not-prose px-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 place-items-center items-center">
          <img
            src={usz}
            alt="USZ - Universitätsspital Zürich - University Hospital Zurich"
            className="w-56"
          />
          <img
            src={uzh}
            alt="UZH - Universität Zürich - University of Zurich"
            className="w-56"
          />
          <img
            src={knz}
            alt="KNZ - Klinisches Neurozentrum Zürich "
            className="w-56"
          />
          <StaticImage
            src="../resources/assets/glimr.png"
            alt="GliMR"
            layout="fixed"
            width={180}
            formats={["webp", "auto"]}
            placeholder="tracedSVG"
          />
          <StaticImage
            src="../resources/assets/ks.png"
            alt="krebsliga schweiz"
            layout="fixed"
            width={256}
            formats={["webp", "auto"]}
            placeholder="tracedSVG"
          />
          <StaticImage
            src="../resources/assets/stiftung.png"
            alt="stiftung"
            layout="fixed"
            width={280}
            formats={["webp", "auto"]}
            placeholder="tracedSVG"
          />
        </div>
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query {
    upds: allStoryblokEntry(
      filter: { field_component: { eq: "update" }, tag_list: { in: "p-home" } }
    ) {
      nodes {
        slug
        content
      }
    }
  }
`;

export default Index;
