import React from "react";
import VideoSporco from "../video-sporco";
import vid from "../../resources/assets/vid.mp4";

export default function VideoHeader() {
  return (
    <div className="">
      <div className="pb-32 bg-black">
        <div className="relative gap-4 max-w-7xl mx-auto pt-12 lg:pt-16 pb-12 px-4 sm:px-6 lg:px-8 flex flex-wrap lg:flex-nowrap justify-center lg:justify-between items-center">
          <div className="w-10/12 lg:w-4/12 mx-auto lg:order-last flex-shrink-0">
            <VideoSporco
              url={vid}
              className="object-cover object-center mx-auto lg:mr-0 w-full"
            />
          </div>
          <div className="text-center lg:text-left w-full lg:w-auto ">
            <p className="text-xl md:text-2xl lg:text-3xl text-white">
              Welcome to the
            </p>
            <h1 className="text-4xl font-extrabold tracking-tight text-white md:text-5xl lg:text-6xl mt-6">
              ASTRAN Lab
            </h1>
            <p className="text-blue-300 text-lg lg:text-xl firs:text-white font-extrabold">
              <span className="text-white">A</span>dvanced{" "}
              <span className="text-white">S</span>timulus-based{" "}
              <span className="text-white">Tra</span>nslational{" "}
              <span className="text-white">N</span>euroimaging{" "}
              <span className="text-white">Lab</span>oratory
            </p>
            <p className="text-lg text-white mt-6">
              affiliated with the Department of Neurosurgery and the Clinical
              Neuroscience Center of University Hospital Zurich
            </p>
          </div>
        </div>
      </div>

      <section
        className="-mt-32 max-w-7xl mx-auto relative z-10 pb-16 px-4 sm:px-6 lg:px-8"
        aria-labelledby="contact-heading"
      >
        <h2 className="sr-only" id="contact-heading">
          Who are we?
        </h2>
        <div className="flex justify-center relative z-10">
          <div className="rounded-lg shadow-lg lg:text-xl max-w-xs sm:max-w-xl p-8 bg-white">
            Our work focuses on the hemodynamic assessment of patients with
            cerebrovascular diseases and brain tumors through advanced
            neuroimaging during respiratory challenges. By means of prospective
            end-tidal gas targeting during fMRI BOLD sequence acquisition, we
            are able to estimate hypercapnic cerebrovascular reactivity and
            hypoxic/hyperoxic tissue perfusion parameters
          </div>
        </div>
      </section>
    </div>
  );
}
