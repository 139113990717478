import React from "react";
import { StaticImage } from "gatsby-plugin-image";

export default function OurWork() {
  return (
    <div className="relative py-16 overflow-hidden flex flex-wrap items-center">
      <div className="w-full md:w-1/2">
        <StaticImage
          src="../../resources/assets/zh1.jpg"
          alt=""
          layout="fullWidth"
          formats={["webp", "auto"]}
          aspectRatio={1.5}
        />
      </div>

      <div className="relative p-4 sm:px-6 lg:px-8 w-full md:w-1/2">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            {/* <span className="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">
              introducing
            </span> */}
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              History and Mission
            </span>
          </h1>
        </div>
        <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
          <p>
            Founded in 2013 at the Department of Neurosurgery of the University
            Hospital Zurich, the ASTRAN team has been developing an advanced
            translational MRI technique to better study ischemic stroke
            patients, and patients with malignant brain tumors. Our main focus
            is to develop novel imaging markers that can be translated into
            clinical practice, and we therefore operate in an interdisciplinary
            fashion at the Clinical Neuroscience Center within the Departments
            of Neurosurgery, Neuroradiology and Neurology with strong
            international collaborations.
          </p>
        </div>
      </div>

      <div className="w-full md:w-1/2 md:order-4">
        <StaticImage
          src="../../resources/assets/zh2.jpg"
          alt=""
          layout="fullWidth"
          formats={["webp", "auto"]}
          aspectRatio={1.5}
        />
      </div>

      <div className="relative p-4 sm:px-6 lg:px-8 w-full md:w-1/2 md:order-3">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            {/* <span className="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">
              introducing
            </span> */}
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Technical developments
            </span>
          </h1>
        </div>
        <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
          <p>
            A key development of our MRI technique has been the precise control
            of endogenous carbon dioxide (CO2) and oxygen (O2) during a blood
            oxygenation-level dependent (BOLD) MRI examination, to better
            interrogate cerebrovascular autoregulation and cerebral blood flow
            changes in stroke patients. For patients with brain tumors, a more
            comprehensive hemodynamic tissue profile can be derived from changes
            in hypercapnia (i.e., high CO2 levels) and hypoxia (low oxygen
            levels). The precise gas control is provided by the -RespirActTM-
            system, which has been pioneered at the University of Toronto under
            supervision of Dr. Joseph A Fisher and Dr. David J Mikulis, under
            whom the senior group leader of the ASTRAN team, Dr. Jorn Fierstra,
            was working as a PhD candidate to further develop this technique.
          </p>
        </div>
      </div>

      <div className="w-full md:w-1/2 md:order-5">
        <StaticImage
          src="../../resources/assets/zh3.jpg"
          alt=""
          layout="fullWidth"
          formats={["webp", "auto"]}
          aspectRatio={1.5}
        />
      </div>

      <div className="relative p-4 sm:px-6 lg:px-8 w-full md:w-1/2 md:order-6">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            {/* <span className="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">
              introducing
            </span> */}
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Our research
            </span>
          </h1>
        </div>
        <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
          <p>
            All our projects are carried out as research studies, under approval
            of the local ethics board and according to the declaration of
            Helsinki. Over the last 10 years, the ASTRAN team has been
            publishing groundbreaking research in leading journals of clinical
            neuroscience and its members have received competitive third party
            funding and awards on institutional as well as (inter)national
            level.
          </p>
        </div>
      </div>
    </div>
  );
}
